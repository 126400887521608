<template>
  <div
    v-if="visible"
    class="modal_window fixed left-0 top-0 z-90 flex h-full w-full overflow-hidden"
    @keydown.esc="closeDialog"
  >
    <div class="fixed inset-0 bg-neutral-5 bg-opacity-80"></div>
    <div
      ref="dialogContainer"
      role="dialog"
      aria-modal="true"
      :aria-labelledby="id + '_label'"
      class="absolute inset-0 z-10 m-auto flex place-self-center self-center overflow-hidden rounded-none border-0 border-neutral-7 contrast:border-base-1 bg-base-2 shadow-xl sm:static sm:rounded sm:border sm:pb-2 data-[buttons-slot='false']:pb-2"
      :class="props.class"
      :data-buttons-slot="
        $slots.buttons !== undefined && $slots.buttons !== null
      "
    >
      <div class="relative flex w-full flex-col overflow-hidden">
        <div
          class="flex justify-between rounded-t border-b border-neutral-7 contrast:border-base-1 bg-neutral-9 contrast:bg-c-secondary-0 p-1 align-middle"
        >
          <h3 :id="id + '_label'" class="m-1 font-medium">
            {{ dialogTitle }}
          </h3>
          <ControlButton
            :id="`${id}_close_window`"
            ref="closeButton"
            class="m-1 align-middle"
            button-style="button-compact"
            :inverted="false"
            :aria-label="closeTitle"
            @click="closeDialog"
          >
            <template #icon>
              <IconPzo name="close" />
            </template>
          </ControlButton>
        </div>
        <div
          ref="dialogContent"
          :class="contentClass"
          class="data-[buttons-slot='true']:mb-14 sm:data-[buttons-slot='true']:mb-0"
          :data-buttons-slot="
            $slots.buttons !== undefined && $slots.buttons !== null
          "
        >
          <div v-if="!initialFocus" tabindex="0"></div>
          <slot></slot>
        </div>
        <div
          v-if="$slots.buttons"
          class="absolute bottom-0 left-0 flex w-full justify-end bg-base-2 sm:static sm:gap-2 sm:px-2"
        >
          <slot name="buttons"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ControlButton from "./ControlButton.vue";
import { computed, ref, watch, nextTick } from "vue";
import { useFocusTrap } from "../composables/useFocusTrap";
import { useFocusableElements } from "../composables/useFocusableElements";
import IconPzo from "./IconPzo.vue";
interface Props {
  dialogTitle: string;
  closeTitle: string;
  id: string;
  modelValue: boolean;
  initialFocus?: boolean;
  focusOnClose: HTMLElement | undefined | { focus: () => void };
  class?: string;
  contentClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  initialFocus: true,
  class: "max-w-xl sm:h-auto sm:w-auto",
  contentClass: "mb-16 p-2 sm:p-4 sm:mb-0 overflow-auto",
});

const dialogContainer = ref<HTMLElement>();
const dialogContent = ref<HTMLElement>();
const focusTrap = useFocusTrap(dialogContainer);
const focusableElements = useFocusableElements(dialogContent);
const closeButton = ref<HTMLElement>();

const visible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "close"): void;
}>();

watch(
  visible,
  async (newValue) => {
    if (newValue) {
      await nextTick();
      focusTrap.updateFocusableElements();
      focusTrap.initFocusTrap();
      focusableElements.updateFocusableElements();
      if (focusableElements.firstFocusableElement.value) {
        focusableElements.firstFocusableElement.value.focus();
      } else {
        closeButton.value?.focus();
      }
    } else {
      props.focusOnClose?.focus();
      focusTrap.clearFocusTrap();
    }
  },
  { immediate: true },
);

function closeDialog() {
  visible.value = false;
  emit("close");
}
</script>
