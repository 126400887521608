<template>
  <div
    :data-invalid="!validationResult.valid"
    :data-disabled="isDisabled"
    :data-mode-size="modeSize"
    :data-mode-orientation="modeOrientation"
    class="group group/input flex flex-col mb-2"
  >
    <RequiredLabel
      v-if="!noLabel"
      :for="id"
      class="text-sm group-focus-within:text-primary-1"
      :required="required"
      :disabled="isDisabled"
    >
      {{ label }}
    </RequiredLabel>
    <div
      class="group-data-[mode-size='normal']:h-10 group-data-[mode-size='large']:py-2 group-data-[mode-size='large']:pr-2 group-data-[mode-size='large']:pl-4 group-data-[mode-size='large']:text-3xl items-center px-2 flex flex-row gap-1 rounded ring-1 ring-base-1 ring-offset-0 group-focus-within:text-primary-1 group-focus-within:ring-2 group-focus-within:ring-primary-1 group-data-disabled:ring-neutral-6 group-data-disabled:text-neutral-4 group-data-invalid:bg-supporting-19 contrast:group-data-invalid:bg-c-secondary-0"
    >
      <ControlButton
        v-if="modeOrientation === 'horizontal'"
        :id="id + '_left'"
        ref="leftButtonRef"
        :tabindex="-1"
        button-style="button-compact"
        :inverted="false"
        type="button"
        class="hover:group-data-invalid:bg-supporting-17 group-data-[mode-size='large']:text-lg self-center"
        :aria-label="localization.previousValue"
        :disabled="isDisabled"
        @click="previousValueClick"
      >
        <template #icon>
          <IconPzo name="chevron-left" />
        </template>
      </ControlButton>
      <input
        :id="id"
        ref="inputElement"
        :value="currentValueDisplay ?? inputValue"
        type="text"
        :inputmode="listSpec !== undefined ? 'numeric' : undefined"
        :readonly="listSpec !== undefined"
        class="outline-none appearance-none grow bg-transparent w-full group-data-[mode-orientation='horizontal']:text-center"
        role="spinbutton"
        :disabled="isDisabled"
        :aria-invalid="!validationResult.valid"
        :aria-errormessage="
          !validationResult.valid ? id + '_error_message' : undefined
        "
        :required="required"
        :aria-label="ariaLabel"
        :aria-valuenow="currentValueIndex"
        :aria-valuetext="
          listSpec !== undefined
            ? getCurrentValue(listSpec.value)?.toString()
            : undefined
        "
        :aria-valuemin="props.min"
        :aria-valuemax="props.max"
        @blur="initializeValidation"
        @keydown.up.prevent="
          listSpec != undefined ? previousValue() : nextValue()
        "
        @keydown.left.prevent="
          listSpec != undefined ? previousValue() : nextValue()
        "
        @keydown.down.prevent="
          listSpec != undefined ? nextValue() : previousValue()
        "
        @keydown.right.prevent="
          listSpec != undefined ? nextValue() : previousValue()
        "
        @keydown.home.prevent="firstValue()"
        @keydown.end.prevent="lastValue()"
        @input="
          inputValue = ($event.target as HTMLInputElement).value;
          updateCurrentValueIndex(
            ($event.target as HTMLInputElement).value,
            props.listSpec?.value,
          );
        "
      />
      <ControlButton
        v-if="modeOrientation === 'horizontal'"
        :id="id + '_right'"
        ref="rightButtonRef"
        :tabindex="-1"
        type="button"
        button-style="button-compact"
        class="hover:group-data-invalid:bg-supporting-17 group-data-[mode-size='large']:text-lg self-center"
        :inverted="false"
        :aria-label="localization.nextValue"
        :disabled="isDisabled"
        @click="nextValueClick"
      >
        <template #icon>
          <IconPzo name="chevron-right" />
        </template>
      </ControlButton>
      <div
        v-if="modeOrientation === 'vertical'"
        class="flex items-center group-data-[mode-size='normal']:gap-1 group-data-[mode-size='large']:flex-col"
      >
        <ControlButton
          :id="id + '_up'"
          ref="upButtonRef"
          :tabindex="-1"
          button-style="button-compact"
          :inverted="false"
          type="button"
          class="hover:group-data-invalid:bg-supporting-17 group-data-[mode-size='large']:text-lg"
          :aria-label="localization.nextValue"
          :disabled="isDisabled"
          @click="nextValueClick"
        >
          <template #icon>
            <IconPzo name="expand-less" />
          </template>
        </ControlButton>
        <ControlButton
          :id="id + '_down'"
          ref="downButtonRef"
          :tabindex="-1"
          type="button"
          button-style="button-compact"
          class="hover:group-data-invalid:bg-supporting-17 group-data-[mode-size='large']:text-lg"
          :inverted="false"
          :aria-label="localization.previousValue"
          :disabled="isDisabled"
          @click="previousValueClick"
        >
          <template #icon>
            <IconPzo name="expand-more" />
          </template>
        </ControlButton>
      </div>
    </div>
    <InputValidationMessage
      v-if="!validationResult.valid"
      :id="id + '_error_message'"
      :message="validationResult.message"
    />
  </div>
</template>
<script setup lang="ts">
import {
  useIntervalFn,
  useMouseInElement,
  useMousePressed,
  watchDebounced,
} from "@vueuse/core";
import { computed, inject, ref, watchEffect, watch } from "vue";
import { formDisabledKey } from "../types/disabled";
import type { ModelValidation, FormValidation } from "../types/formValidation";
import {
  integerNumber,
  valueFromList,
  required as requiredValidator,
  withValidators,
  minNumber,
  maxNumber,
} from "../composables/validators";
import { useFieldValidation } from "../composables/useFieldValidation";
import RequiredLabel from "./RequiredLabel.vue";
import ControlButton from "./ControlButton.vue";
import type ControlButtonType from "./ControlButton.vue";
import InputValidationMessage from "./InputValidationMessage.vue";
import { normalizedEqual } from "../composables/stringUtils";
import IconPzo from "./IconPzo.vue";

interface Localization {
  nextValue: string;
  previousValue: string;
}

interface Props {
  id: string;
  mode?:
    | "large-vertical"
    | "normal-vertical"
    | "normal-horizontal"
    | "large-horizontal";
  listSpec?: {
    list: Record<string | number, string | number | boolean | object>[];
    key: string;
    value: string;
  };
  min?: number;
  max?: number;
  label?: string;
  ariaLabel?: string;
  noLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  formValidation?: FormValidation;
  modelValidators?: ModelValidation[];
  localization?: Localization;
  modelValue?: string | number;
  pad?: {
    char: string;
    start?: number;
    end?: number;
  };
  holdMouseModeEnabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mode: "normal-horizontal",
  listSpec: undefined,
  min: undefined,
  max: undefined,
  noLabel: false,
  label: undefined,
  ariaLabel: undefined,
  disabled: false,
  required: false,
  formValidation: undefined,
  modelValidators: undefined,
  disableValidationMessageMargin: false,
  localization: () => {
    return {
      nextValue: "Następna wartość",
      previousValue: "Poprzednia wartość",
    };
  },
  modelValue: undefined,
  pad: undefined,
  holdMouseModeEnabled: true,
});

const formDisabled = inject(formDisabledKey, false);
const isDisabled = computed(() => {
  return formDisabled || props.disabled;
});

const allValidators = computed(() => {
  return withValidators(
    [],
    [
      [requiredValidator, props.required === true],
      [integerNumber, props.listSpec === undefined],
      [
        minNumber(props.min),
        props.listSpec === undefined && props.min !== undefined,
      ],
      [
        maxNumber(props.max),
        props.listSpec === undefined && props.min !== undefined,
      ],
      [
        valueFromList(props.listSpec?.list ?? [], props.listSpec?.value ?? ""),
        props.listSpec !== undefined,
      ],
    ],
  );
});

const currentValueIndex = ref<number>();

const upButtonRef = ref<InstanceType<typeof ControlButtonType>>();
const downButtonRef = ref<InstanceType<typeof ControlButtonType>>();
const leftButtonRef = ref<InstanceType<typeof ControlButtonType>>();
const rightButtonRef = ref<InstanceType<typeof ControlButtonType>>();
const holdMouseMode = ref(false);
const lastUpdateWithHoldMouse = ref(false);

if (props.holdMouseModeEnabled) {
  const upButtonRefRoot = computed(() => {
    return upButtonRef.value?.rootNode;
  });
  const downButtonRefRoot = computed(() => {
    return downButtonRef.value?.rootNode;
  });
  const leftButtonRefRoot = computed(() => {
    return leftButtonRef.value?.rootNode;
  });
  const rightButtonRefRoot = computed(() => {
    return rightButtonRef.value?.rootNode;
  });

  const { pressed: pressedUp } = useMousePressed({
    drag: false,
    target: upButtonRefRoot,
  });
  const { pressed: pressedDown } = useMousePressed({
    drag: false,
    target: downButtonRefRoot,
  });
  const { pressed: pressedLeft } = useMousePressed({
    drag: false,
    target: leftButtonRefRoot,
  });
  const { pressed: pressedRight } = useMousePressed({
    drag: false,
    target: rightButtonRefRoot,
  });

  const { isOutside: upIsOutside } = useMouseInElement(upButtonRefRoot);
  const { isOutside: downIsOutside } = useMouseInElement(downButtonRefRoot);
  const { isOutside: leftIsOutside } = useMouseInElement(leftButtonRefRoot);
  const { isOutside: rightIsOutside } = useMouseInElement(rightButtonRefRoot);
  const HOLD_MOUSE_MODE_DEBOUNCE = 500;
  const HOLD_MOUSE_MODE_INTERVAL = 100;

  watchDebounced(
    [pressedUp, pressedDown, pressedLeft, pressedRight],
    ([newPressedUp, newPressedDown, newPressedLeft, newPressedRight]) => {
      if (newPressedUp || newPressedDown || newPressedLeft || newPressedRight) {
        holdMouseMode.value = true;
      } else {
        holdMouseMode.value = false;
      }
    },
    { debounce: HOLD_MOUSE_MODE_DEBOUNCE },
  );

  watch(
    [pressedUp, pressedDown, pressedLeft, pressedRight],
    ([newPressedUp, newPressedDown, newPressedLeft, newPressedRight]) => {
      if (
        !newPressedUp &&
        !newPressedDown &&
        !newPressedLeft &&
        !newPressedRight
      ) {
        holdMouseMode.value = false;
      }
    },
  );

  const { pause, resume } = useIntervalFn(() => {
    if (isDisabled.value || !holdMouseMode.value) {
      return;
    }
    if (
      (pressedUp.value && upButtonRefRoot.value && !upIsOutside.value) ||
      (pressedRight.value && rightButtonRefRoot.value && !rightIsOutside.value)
    ) {
      nextValue();
      lastUpdateWithHoldMouse.value = true;
    }
    if (
      (pressedDown.value && downButtonRefRoot.value && !downIsOutside.value) ||
      (pressedLeft.value && leftButtonRefRoot.value && !leftIsOutside.value)
    ) {
      previousValue();
      lastUpdateWithHoldMouse.value = true;
    }
  }, HOLD_MOUSE_MODE_INTERVAL);

  watchEffect(() => {
    if (
      pressedUp.value ||
      pressedDown.value ||
      pressedLeft.value ||
      pressedRight.value
    ) {
      resume();
    } else {
      pause();
    }
  });
}

function nextValueClick() {
  if (
    isDisabled.value ||
    holdMouseMode.value ||
    lastUpdateWithHoldMouse.value
  ) {
    lastUpdateWithHoldMouse.value = false;
    return;
  }
  nextValue();
}

function previousValueClick() {
  if (
    isDisabled.value ||
    holdMouseMode.value ||
    lastUpdateWithHoldMouse.value
  ) {
    lastUpdateWithHoldMouse.value = false;
    return;
  }
  previousValue();
}

const inputValue = ref<string>();
const inputElement = ref<HTMLInputElement>();
const currentValue = computed({
  get() {
    return getValueIfAllowed(props.modelValue, props.listSpec?.key);
  },
  set(value) {
    updateCurrentValueIndex(value, props.listSpec?.key);
    emit("update:modelValue", getCurrentValue(props.listSpec?.key));
  },
});
const currentValueDisplay = computed(() => {
  const currentValue = getCurrentValue(props.listSpec?.value);
  if (props.pad !== undefined) {
    return currentValue
      ?.toString()
      .padEnd(props.pad.end ?? 0, props.pad.char)
      ?.padStart(props.pad.start ?? 0, props.pad.char);
  }
  return currentValue;
});

function getValueIfAllowed(
  value: string | number | undefined,
  key: string | undefined,
) {
  if (value === undefined) {
    return undefined;
  }
  if (props.listSpec !== undefined && key !== undefined) {
    const foundValue = props.listSpec.list.find((item) => {
      if (props.listSpec !== undefined && key !== undefined) {
        return normalizedEqual(item[key].toString(), value.toString());
      }
      return false;
    });
    if (foundValue === undefined) {
      return undefined;
    }
    return foundValue[key];
  } else if (typeof value === "number" || !isNaN(Number(value))) {
    return Number(value);
  }
  return undefined;
}

function getCurrentValue(key: string | undefined) {
  if (currentValueIndex.value === undefined) {
    return undefined;
  }
  if (props.listSpec !== undefined && key !== undefined) {
    return props.listSpec.list[currentValueIndex.value][key];
  }
  return currentValueIndex.value;
}

function updateCurrentValueIndex(
  newValue: string | number | boolean | object | undefined,
  key: string | undefined,
  setUndefined = true,
) {
  let valueToSet: string | number | undefined = undefined;
  if (newValue !== undefined && newValue !== "") {
    if (props.listSpec !== undefined) {
      const index = props.listSpec.list.findIndex((item) => {
        if (props.listSpec !== undefined && key !== undefined) {
          return normalizedEqual(item[key].toString(), newValue.toString());
        }
        return false;
      });
      if (index !== -1) {
        valueToSet = index;
      }
    } else if (typeof newValue === "number" || !isNaN(Number(newValue))) {
      valueToSet = Number(newValue);
    }
  } else {
    valueToSet = undefined;
  }
  if (setUndefined || valueToSet !== undefined) {
    currentValueIndex.value = valueToSet;
  }
}

watch(currentValueIndex, () => {
  currentValue.value = getCurrentValue(props.listSpec?.key);
});

watch(
  currentValue,
  (newValue) => {
    updateCurrentValueIndex(newValue, props.listSpec?.key);
    if (newValue !== undefined) {
      const valueToSet = getCurrentValue(props.listSpec?.value)?.toString();
      if (props.pad !== undefined) {
        inputValue.value = valueToSet
          ?.padEnd(props.pad.end ?? 0, props.pad.char)
          ?.padStart(props.pad.start ?? 0, props.pad.char);
      } else if (props.listSpec !== undefined) {
        inputValue.value = valueToSet;
      }
    }
  },
  { immediate: true },
);

const emit = defineEmits<{
  (
    e: "update:modelValue",
    value?: string | number | boolean | object | undefined,
  ): void;
}>();

const { validationResult, initializeValidation, resetValidation } =
  useFieldValidation(
    props.id,
    inputValue,
    allValidators,
    props.modelValidators,
    props.formValidation,
  );

function nextValue() {
  if (isDisabled.value) {
    return;
  }
  let valueToSet: string | number | undefined = undefined;
  if (currentValueIndex.value === undefined) {
    if (props.min) {
      valueToSet = props.min;
    } else {
      valueToSet = 0;
    }
  }
  if (
    currentValueIndex.value !== undefined &&
    props.listSpec &&
    currentValueIndex.value + 1 >= props.listSpec.list.length
  ) {
    valueToSet = 0;
  }
  if (
    currentValueIndex.value !== undefined &&
    props.max !== undefined &&
    currentValueIndex.value + 1 > props.max
  ) {
    if (props.min !== undefined) {
      valueToSet = props.min;
    } else {
      valueToSet = currentValueIndex.value;
    }
  }
  if (valueToSet === undefined && currentValueIndex.value !== undefined) {
    valueToSet = currentValueIndex.value + 1;
  }

  if (valueToSet !== currentValueIndex.value) {
    currentValueIndex.value = valueToSet;
  }
}

function previousValue() {
  if (isDisabled.value) {
    return;
  }
  let valueToSet: string | number | undefined = undefined;
  if (currentValueIndex.value === undefined) {
    if (props.listSpec && props.listSpec.list.length > 0) {
      valueToSet = props.listSpec.list.length - 1;
    } else if (props.max) {
      valueToSet = props.max;
    }
  }
  if (
    currentValueIndex.value !== undefined &&
    props.listSpec &&
    currentValueIndex.value - 1 < 0
  ) {
    valueToSet = props.listSpec.list.length - 1;
  }
  if (
    currentValueIndex.value !== undefined &&
    props.min !== undefined &&
    currentValueIndex.value - 1 < props.min
  ) {
    if (props.max !== undefined) {
      valueToSet = props.max;
    } else {
      valueToSet = currentValueIndex.value;
    }
  }
  if (valueToSet === undefined && currentValueIndex.value !== undefined) {
    valueToSet = currentValueIndex.value - 1;
  }
  if (valueToSet !== currentValueIndex.value) {
    currentValueIndex.value = valueToSet;
  }
}

function firstValue() {
  if (isDisabled.value) {
    return;
  }
  let valueToSet: string | number | undefined = undefined;
  if (props.listSpec && props.listSpec.list.length > 0) {
    valueToSet = 0;
  } else if (props.min !== undefined) {
    valueToSet = props.min;
  }
  if (valueToSet !== undefined && valueToSet !== currentValueIndex.value) {
    currentValueIndex.value = valueToSet;
  }
}

function lastValue() {
  if (isDisabled.value) {
    return;
  }
  let valueToSet: string | number | undefined = undefined;
  if (props.listSpec && props.listSpec.list.length > 0) {
    valueToSet = props.listSpec.list.length - 1;
  } else if (props.max !== undefined) {
    valueToSet = props.max;
  }
  if (valueToSet !== undefined && valueToSet !== currentValueIndex.value) {
    currentValueIndex.value = valueToSet;
  }
}

const modeOrientation = computed(() => {
  return props.mode === "normal-horizontal" || props.mode === "large-horizontal"
    ? "horizontal"
    : "vertical";
});

const modeSize = computed(() => {
  return props.mode === "large-horizontal" || props.mode === "large-vertical"
    ? "large"
    : "normal";
});

function focus() {
  inputElement.value?.focus();
}

defineExpose({ resetValidation, focus });
</script>
